import ReactPlayerLoader from '@brightcove/react-player-loader'
import { Breadcrumb } from 'gatsby-plugin-breadcrumb'
import * as React from 'react'
import styles from './[contentId].module.scss'

import Seo from '@/components/general/seo'
import SpecificButton from '@/components/general/specificButton'
import Layout from '@/components/layout/layout'
import SectionTitle from '@/components/top/section-title'

import { BreadcrumbContext } from '@/types/breadcrumb'
import { ACCOUNT_ID } from '@/lib/constants'

const Page: React.FC<BreadcrumbContext> = ({ pageContext }) => {
  const {
    breadcrumb: { crumbs },
  } = pageContext
  return (
    <Layout>
      <Seo title="同じ職場のBさん、どうも無関心で活動力や自発性が欠けているように見えてしまう こんな経験ありませんか？" />
      <div className={styles.wrap}>
        <div className={styles.wrapInner}>
          <div className={styles.answer}>
            <p className={styles.comment}>
              もしかしたら遂行機能障害のサインかも。
            </p>
          </div>
          <div className={styles.moreWrap}>
            <div className={styles.more}>
              <span className={styles.moreText}>詳しく解説</span>
            </div>
            <div className={styles.dotLine} />
          </div>
          <div className={styles.explanation}>
            <p>
              遂行機能障害とは、「目標を設定し、そのプロセスを計画、効果的に行動していく事が出来なくなる」高次脳機能障害のひとつです。高次脳機能とは、知覚、記憶、判断など、人間が人間らしくあるための（脳の）認知機能の総称です。遂行機能障害の代表的な症状として「行動が開始できない」目標が立てることが出来ない、もしくは目標を立ててもプロセスが計画出来ないので行動を開始できにくいという症状があります。このようなサインを見逃さずに手を差し伸べてあげましょう。
            </p>
          </div>
          <SectionTitle theme="dark">Cognition Cafe Reserve</SectionTitle>
          <div className={styles.video}>
            <ReactPlayerLoader
              accountId={ACCOUNT_ID}
              videoId="6312731365112"
              playerId="hM1SBfBel"
              embedOptions={{ responsive: 'aspectRatio' }}
            />
            <p className={styles.caption}>
              大日本住友製薬（現：住友ファーマ株式会社）
            </p>
          </div>
          <SpecificButton videoId={5} />
          <Breadcrumb
            crumbs={crumbs}
            crumbSeparator=" > "
            crumbLabel={
              '同じ職場のBさん、どうも無関心で活動力や自発性が欠けているように見えてしまう こんな経験ありませんか？'
            }
          />
        </div>
      </div>
    </Layout>
  )
}

export default Page
